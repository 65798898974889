<template>
  <default-layout>
    <div class="container directory--container">
      <div class="row">
        <div class="col-12 col-md-8 left--col">
          <h1 class="directory--title mb-3">
            {{ $t('directory.title') }} {{ cleanPropertyTypeName }}
          </h1>
          <district></district>
          <div class="row mt-4">
            <directory-project
              class="col-12 directory--project-wrapper"
              :propertyTypeName="cleanPropertyTypeName"
            ></directory-project>
          </div>
        </div>
        <div class="col-12 col-md-4 right--col search--filters">
          <h2 class="directory--title mb-3">
            {{ $t('directory.search.title') }} {{ cleanPropertyTypeName }}
          </h2>
          <district-filter></district-filter>
          <!--          <building-size :changeRoute="false"></building-size>-->
          <hr />
          <button class="btn btn-primary btn-block mt-4" @click="openSearchPage">
            {{ $t('directory.search.searchBtn') }}
          </button>
        </div>
      </div>
    </div>
  </default-layout>
</template>

<script>
const District = () => import('@/components/directory/district');
const DirectoryProject = () => import('@/components/directory/project');
const DistrictFilter = () => import('@/components/directory/district-filter');
// const BuildingSize = () => import('@/components/search/partials/filters-sidebar/building-size');
import DefaultLayout from '@/layouts/default';
import { mapState } from 'vuex';

export default {
  components: {
    DefaultLayout,
    District,
    DirectoryProject,
    DistrictFilter,
    // BuildingSize,
  },
  computed: {
    ...mapState({
      headerHeight: (state) => state.size.headerHeight,
      propertyTypes: (state) => state.v2.masters.propertyTypes,
      selectedCity: (state) => state.v2.directory.selectedCity,
      buildingSizeMin: (state) => state.v2.search.buildingSizeMin,
      buildingSizeMax: (state) => state.v2.search.buildingSizeMax,
    }),
    cleanPropertyTypeName() {
      return this.$route.params.propertyType
        ? this.$route.params.propertyType.replace(/-/g, ' ')
        : '';
    },
  },
  async fetch({ store, params, route }) {
    await store.dispatch('v2/masters/getPropertyTypes');
    const propertyType = await store.dispatch(
      'v2/masters/getPropertyTypeByName',
      params.propertyType,
    );

    try {
      await store.dispatch(
        'v2/directory/getDirectoryData',
        propertyType ? propertyType.name : null,
      );
      // eslint-disable-next-line no-empty
    } catch (e) {}

    const baseUrl = store.state.global.baseUrl;
    const title =
      'BeliRumah.co - Direktori ' +
      (propertyType ? propertyType.name.toLowerCase() : '') +
      ' | BeliRumah';
    const description =
      'BeliRumah.co menyediakan properti dengan tipe ' +
      (propertyType ? propertyType.name.toLowerCase() : '') +
      ' dan membantu Anda mencari serta membeli rumah dan apartemen. | BeliRumah';
    const head = {
      title: title,
      meta: [
        { hid: 'og-url', property: 'og:url', content: baseUrl + route.fullPath },
        { hid: 'og-type', property: 'og:type', content: 'article' },
        {
          hid: 'og-title',
          property: 'og:title',
          content: title,
        },
        {
          hid: 'og-description',
          property: 'og:description',
          content: description,
        },
        { hid: 'og-site-name', property: 'og:site_name', content: 'BeliRumah' },
        { hid: 'og-image-width', property: 'og:image:width', content: '450' },
        { hid: 'og-image-height', property: 'og:image:height', content: '450' },
        { hid: 'og-fb-app_id', property: 'fb:app_id', content: '2017706101791817' },
        {
          hid: 'description',
          name: 'description',
          content: description,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: baseUrl + route.fullPath,
          id: 'canonical',
        },
      ],
    };
    store.commit('head/SET_CONTENT', head);
  },
  methods: {
    openSearchPage() {
      let city = this.selectedCity;
      let searchRoute = '';
      let propertyType = this.$route.params.propertyType;
      if (city && city.name) {
        searchRoute = encodeURI(
          '/cari/' +
            propertyType +
            '/' +
            city.name.toLowerCase().replace('-', '__').split(' ').join('-').replace(/\//g, '') +
            '?category=city&category_filter_id=' +
            city.id,
        );
        if (this.buildingSizeMin) searchRoute += '&building_size_min=' + this.buildingSizeMin;
        if (this.buildingSizeMax) searchRoute += '&building_size_max=' + this.buildingSizeMax;
      } else {
        searchRoute = encodeURI('/cari/' + propertyType);
        if (this.buildingSizeMin) searchRoute += '?building_size_min=' + this.buildingSizeMin;
        if (this.buildingSizeMax) searchRoute += '&building_size_max=' + this.buildingSizeMax;
      }
      this.$router.push(searchRoute);
    },
  },
};
</script>
